module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"56fa66851c61043abfec76d786c79986"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MWXRVW8","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"arbitery","schemas":{"post":{"Main":{"uid":{"type":"UID","config":{"label":"URL"}},"preview_image":{"type":"Image","config":{"constraint":{"width":1920,"height":1080},"thumbnails":[{"name":"square","width":1920,"height":1920},{"name":"portrait","width":1920,"height":1440},{"name":"small","width":656,"height":300}],"label":"Preview Image"}},"title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Title"}},"seo_title":{"type":"Text","config":{"label":"SEO Title"}},"description":{"type":"Text","config":{"label":"Description"}},"content":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","label":"content"}},"author":{"type":"Link","config":{"select":"document","customtypes":["author"],"label":"Author"}},"related":{"type":"Group","config":{"fields":{"posts":{"type":"Link","config":{"select":"document","label":"posts"}}},"label":"Related"}},"categories":{"type":"Group","config":{"fields":{"category":{"type":"Link","config":{"select":"document","customtypes":["post_category"],"label":"Category"}}},"label":"Categories"}}}},"author":{"Main":{"avatar":{"type":"Image","config":{"constraint":{"width":64,"height":64},"thumbnails":[],"label":"Avatar"}},"name":{"type":"Text","config":{"label":"Name"}}}},"post_category":{"Main":{"title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Title"}},"description":{"type":"Text","config":{"label":"Description"}}}}}},
    }]
